import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import './plugins/vue-world-map'
import i18n from './i18n'
import '@mdi/font/css/materialdesignicons.css'
import vuetify from './plugins/vuetify'
import ViewUI from 'view-design'
import 'view-design/dist/styles/iview.css'
import formCreate from '@form-create/iview4'
import {
  TreeSelect,
} from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import axios from 'Axios'
import moment from 'moment'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import CustomTimeline from './views/form/CustomTimeline'
import VueTianditu from 'vue-tianditu'
import Directives from './directives'
import filters from './filters'
// import * as Sentry from '@sentry/browser'
// import * as Integrations from '@sentry/integrations'
import * as echarts from 'echarts'
// import FcDesigner from '@form-create/designer'
// Vue.use(FcDesigner)
Vue.component('CustomTimeline', CustomTimeline)

// Sentry.init({
//   dsn: 'http://58035ebc782c47648f0f159125e68a59@bug.kp.sdcom.gov.cn/8',
//   integrations: [new Integrations.Vue({ Vue, attachProps: true })]
// });

Vue.use(VueTianditu, {
  v: '4.0',
  tk: '48e77202f4f8e99683f263a54049f0b2',
})
moment.locale('zh-cn')
Vue.prototype.$moment = moment
Vue.prototype.$echarts = echarts
Vue.prototype.$axios = axios
Vue.config.productionTip = false
Vue.use(ViewUI)
Vue.use(formCreate)
Vue.use(TreeSelect)
Vue.use(VueSweetalert2)
Vue.use(ElementUI)
Vue.use(Directives)

Object.keys(filters).forEach(k => {
  Vue.filter(k, filters[k])
})

Vue.config.devtools = true

// 请求统计数
let loadingCount = 0

// 添加请求数量
function addLoading () {
  store.commit('SET_ISSHOWLOADING', true)
  loadingCount++
}

// 减去请求数量
function isCloseLoading () {
  loadingCount--
  if (loadingCount === 0) {
    store.commit('SET_ISSHOWLOADING', false)
  }
}

// axios的基础API地址
axios.defaults.baseURL = 'https://zdwz.api.sdcom.gov.cn/api'

// 请求前置拦截器
axios.interceptors.request.use(
  (config) => {
    if (localStorage.xApp) {
      config.headers['x-app'] = localStorage.xApp
    }
    if (localStorage.token) {
      config.headers['authorization'] = localStorage.token
    }
    addLoading()
    return config
  },
  (err) => {
    return Promise.reject(err)
  },
)
// 请求后置拦截器
axios.interceptors.response.use(
  (response) => {
    isCloseLoading()
    return response
  },
  (error) => {
    console.log('error', error);

    if (error.response.status === 401) {
      console.log(error.response.status)
      localStorage.clear()
      router.push('/pages/login')
    }
    return Promise.reject(error)
  },
)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
