import { render, staticRenderFns } from "./Setup.vue?vue&type=template&id=02a3dadf&scoped=true"
import script from "./Setup.vue?vue&type=script&lang=js"
export * from "./Setup.vue?vue&type=script&lang=js"
import style0 from "./Setup.vue?vue&type=style&index=0&id=02a3dadf&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02a3dadf",
  null
  
)

export default component.exports