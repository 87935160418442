<template>
  <v-card
    class="mx-auto mt-0"
    :color="color"
    dark
  >
    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline mb-4 d-none d-sm-flex">
          {{ title }}
        </div>
        <v-list-item-title
          :class="btnSmall?'card_title text-h5 mb-0':'text-h5 mb-0'"
        >
          {{ title }}
        </v-list-item-title>
        <v-list-item-subtitle
          :class="btnSmall?'card_title_neo':''"
        >
          {{ sendBtn ?'目前共填报': '目前项目数共' }}<b>{{ count }}</b>个{{ name }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-avatar
        tile
        :size="size"
      >
        <v-icon
          :class="'green--text '+ icon"
          color="grey lighten-3"
          :size="sizeIcon"
        />
      </v-list-item-avatar>
    </v-list-item>

    <v-card-actions class="pa-3 pt-0 pt-sm-3 pt-md-3 pt-lg-3 pt-xl-3 d-flex">
      <v-btn
        v-show="params.formId === wsFormId"
        v-permission="['ROLE_TZCJZX','ROLE_TZCJCNBYJ','ROLE_KFQCNBYJ','ROLE_TZCJCFZRSHYJ']"
        outlined
        @click="upload(form)"
        :small="btnSmall"
        :class="btnSmall?'card_but':''"
      >
        <!-- <v-icon
          left
          class="ml-0"
        >
          mdi-cloud-upload
        </v-icon> -->
        导入
      </v-btn>
      <v-btn
        v-show="sendBtn"
        v-notPermission="['ROLE_TZCJCNBYJ','ROLE_KFQCNBYJ','ROLE_TZCJCFZRSHYJ']"
        outlined
        :to="`/report/?formId=${params.formId}`"
        :small="btnSmall"
        :class="btnSmall?'card_but':''"
      >
        <v-icon
          left
          class="ml-0 d-none d-sm-flex"
        >
          fa fa-plus
        </v-icon>
        新增
      </v-btn>
      <v-btn
        v-if="listBtn"
        :small="btnSmall"
        :class="btnSmall?'card_but':''"
        outlined
        @click="see(params.formId)"
      >
        查看
      </v-btn>
    </v-card-actions>
    <upload-dialog ref="uploadDialog" />
  </v-card>
</template>

<script>
  import http from '../../../api'
  import qs from 'qs'
  export default {
    components: {
      UploadDialog: () => import('./UploadDialog'),
    },
    props: {
      title: {
        type: String,
        default: '项目列表',
      },
      color: {
        type: String,
        default: 'green darken-1',
      },
      icon: {
        type: String,
        default: 'fa4 fa-home',
      },
      formId: {
        type: String,
        default: '',
      },
      form: {
        type: Object,
        default: null,
      },
      params: {
        type: Object,
        default: null,
      },
      sendBtn: {
        type: Boolean,
        default: true,
      },
      listBtn: {
        type: Boolean,
        default: true,
      },
    },
    data () {
      return {
        count: 0,
        name: null,
        cardColor: null,
        orgQX: false,
        isDeputy: false,
        projectTotal: 0,
        projectTotal2: 0,
        isEdit: false,
        // 推介
        tjFormId: 'defdcd74-fe79-4906-b3aa-dbdb0ce55afe',
        wqFormId: 'eeadced3-7c53-4e4d-9f17-a334a28e10ad',
        // 外商
        wsFormId: 'bce0f632-42a8-4cf7-a30c-e5c6f00c6e62',
        // 外商推送
        tsFormId1: '5afc5ec4-b2a0-49dc-b042-75d0079b396c',
        // 对外推送
        tsFormId2: '76fa7297-55e4-4791-a239-897b24dad6a3',
        size: 80,
        sizeIcon: 60,
        btnSmall: false,
      }
    },
    computed: {
      queryParams: function () {
        return qs.stringify(this.params)
      },
    },
    watch: {
      '$vuetify.breakpoint.name': {
        handler (name) {
          if (name === 'xs') {
            this.size = 30
            this.sizeIcon = 30
            this.btnSmall = true
          } else if (name === 'sm') {
            this.size = 60
            this.sizeIcon = 50
            this.btnSmall = false
          } else {
            this.size = 80
            this.sizeIcon = 60
            this.btnSmall = false
          }
        },
        deep: true,
        // 代表在wacth里声明了这个方法之后立即先去执行handler方法
        immediate: true,
      },
    },
    created () {
      this.isEdit = localStorage.isEdit === 'true' || localStorage.isEdit === true
      this.isDeputy = localStorage.isDeputy === 'true' || localStorage.isDeputy === true
      this.orgQX = localStorage.orgQX === 'true' || localStorage.orgQX === true
    },
    mounted () {
      // console.log(this.formId)
      setTimeout(() => {
        this.getReportCount()
      }, 500);
    },
    methods: {
      upload: function (form) {
        console.log('form', form);
        this.$refs.uploadDialog.open(form);
      },
      see (formId) {
        if (this.formId === this.wsFormId && this.orgQX) {
          this.params._from = 'grant'
          this.params['formId'] = this.tsFormId1
          this.$router.push({
            path: '/city-list',
            query: this.params,
          })
        } else if (this.formId === this.tjFormId && this.isDeputy) {
          this.params._from = 'grant'
          this.params['formId'] = this.tsFormId2
          console.log('this.params', this.params);
          this.$router.push({
            path: '/city-list2',
            query: this.params,
          })
        } else {
          if (this.formId === this.tjFormId && this.isEdit) {
            this.params['aStatusList'] = '0,10'
            this.params['statusList'] = '5,10,0'
            this.params['_from'] = 'audit'
            // 领导临时查看
            // this.params['isShow'] = '是'
          } else {
            delete this.params['aStatusList']
            delete this.params['statusList']
            this.params['_from'] = 'org'
          }
          this.$router.push({
            path: '/list',
            query: this.params,
          })
        }
      },

      async getReportCount () {
        if (this.formId === this.wsFormId && this.orgQX) {
          this.params._from = 'grant'
          this.params['formId'] = this.tsFormId1
        } else if (this.formId === this.tjFormId && this.isDeputy) {
          this.params._from = 'grant'
          this.params['formId'] = this.tsFormId2
        } else {
          if (this.formId === this.tjFormId && this.isEdit) {
            this.params['aStatusList'] = '0,10'
            this.params['_from'] = 'audit'
            this.params['statusList'] = '5,10,0'
            // 领导临时查看
            // this.params['isShow'] = '是'
          } else {
            delete this.params['aStatusList']
            delete this.params['statusList']
            this.params['_from'] = 'org'
          }
        }
        const resData = await http.report.queryReportList(this.params)
        this.count = resData.data.data.totalElements
      },

    },
  }
</script>
<style lang="scss" scoped>
.card_but{
font-size: 12px;
font-family: PingFang SC, PingFang SC-Medium;
font-weight: 500;
color: #ffffff;
}
.card_title_neo{
font-size: 13px;
font-family: PingFang SC, PingFang SC-Medium;
font-weight: 500;
color: #ffffff;
}
.card_title{
font-size: 14px;
font-family: PingFang SC, PingFang SC-Bold;
font-weight: 700;
color: #ffffff;
}
</style>
