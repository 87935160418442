填报表单
<template>
  <v-container
    id="regular-tables"
    tag="section"
    fluid
  >
    <v-card
      :class="isXs ? 'mb-0 ' : 'mb-10'"
      :style="isXs ? '' : 'min-width:1400px'"
    >
      <v-breadcrumbs :items="breadcrumbs" />
    </v-card>
    <template v-if="formLoading">
      <v-progress-circular
        :width="3"
        color="green"
        indeterminate
      />
    </template>
    <v-card
      :class="isXs ? 'cardXs pa-0' : 'pa-6'"
      outlined
      :style="isXs ? '' : 'margin:0 auto;width:1400px;max-width:1400px'"
    >
      <form-create
        v-model="formCreateRule.fApi"
        :style="isXs ? '' : 'margin:0 auto;width:1340px'"
        :rule="formCreateRule.rule"
        :option="formCreateRule.option"
      />
      <!-- <div
      id="container"
      style="width: 100%;height: 500px"
    />
    <div class="input-card">
      <label style="color:grey">点标记操作</label>
      <div class="input-item">
        <input
          id="addMarker"
          type="button"
          class="btn"
          value="添加点标记"
          @click="addMarker()"
        >
        <input
          id="updateMarker"
          type="button"
          class="btn"
          value="更新点标记图标"
          @click="updateIcon()"
        >
      </div>
      <div class="input-item">
        <input
          id="clearMarker"
          type="button"
          class="btn"
          value="删除点标记"
          @click="clearMarker()"
        >
        <input
          id="updateMarker"
          type="button"
          class="btn"
          value="更新点标记内容"
          @click="updateContent()"
        >
      </div>
    </div> -->
      <v-card
        v-if="mapBox"
        id="mapBox"
        ref="mapBox"
        class="pa-5 mt-0"
      >
        <div
          id="mapDiv"
          ref="mapDiv"
          class="mapDiv"
        />
        <div class="mapEditBox">
          <!-- <v-btn
          class="mt-3 mr-3"
          color="success"
          rounded
          @click="addIcon"
        >
          <v-icon left>
            fa fa-map-marker
          </v-icon>
          添加标记1
        </v-btn> -->
          <v-btn
            class="mt-3 mr-3"
            color="red darken-3"
            rounded
            @click="remove_icon"
          >
            <v-icon left>
              fa fa-trash-o
            </v-icon>
            清除标记
          </v-btn>
          <v-btn
            v-if="rumap"
            class="mt-3 mr-3"
            color="success"
            rounded
            @click="toggleControl('polygon')"
          >
            <v-icon left>
              fa fa-square-o
            </v-icon>
            添加图形
          </v-btn>
          <v-btn
            v-if="rumap"
            class="mt-3 mr-3"
            color="blue darken-2"
            rounded
            @click="toggleControl('modify')"
          >
            <v-icon left>
              fa fa-pencil-square-o
            </v-icon>
            编辑图形
          </v-btn>
          <v-btn
            v-if="rumap"
            class="mt-3 mr-3"
            color="red darken-3"
            rounded
            @click="remove_vectors"
          >
            <v-icon left>
              fa fa-trash-o
            </v-icon>
            清除图形
          </v-btn>
        </div>
      </v-card>
      <v-card
        v-if="gdmap"
        id="mapBox"
        class="pa-5 mt-0"
      >
        <div
          id="container"
          class="mapDiv"
        />
        <div class="mapEditBox">
          <!-- <v-btn
          class="mt-3 mr-3"
          color="success"
          rounded
          @click="addMarker2"
        >
          <v-icon left>
            fa fa-map-marker
          </v-icon>
          添加标记
        </v-btn> -->
          <v-btn
            class="mt-3 mr-3"
            color="red darken-3"
            rounded
            @click="clear()"
          >
            <v-icon left>
              fa fa-trash-o
            </v-icon>
            清除标记
          </v-btn>
        </div>
      </v-card>
      <!-- <v-btn
      color="info"
      @click="saveBtn()"
    >
      暂存
    </v-btn> -->
      <v-btn
        v-if="formDataBoxs && formDataBoxs.status === 5 && formDataBoxs.data && !formDataBoxs.data.projectStatus2 && isEdit&& (formId===wsFormId||formId===tjFormId)"
        color="green"
        @click="submitBtn('first')"
      >
        通过
      </v-btn>

      <v-btn
        v-else
        color="green"
        @click="submitBtn()"
      >
        提交
      </v-btn>
      <v-alert
        v-if="formId === 'd530b595-f82b-4e50-9bbc-5561225999d4'"
        class="mt-6"
        border="top"
        colored-border
        type="info"
        elevation="2"
        color="#2196F3"
      >
        <div class="mt-2">
          <b>说明： </b>
        </div>
        <div class="mt-2">
          <b>请各代表处提供：</b>驻地政策法规研究、经济形势研究、产业状况研究、市场行情研究、合作重点领域研究以及与驻地产业、公司合作建议。
        </div>
        <div class="mt-2">
          <b>请各地区处提供：</b>有关国家（地区）政策法规研究、经济形势研究、产业状况研究、市场行情研究、合作重点领域研究以及与有关国家（地区）产业、公司合作建议。
        </div>
        <div class="mt-2">
          <b>请外资处提供：</b>利用外资情况分析以及建议。
        </div>
        <div class="mt-2">
          <b>请跨国公司研究中心提供：</b>跨国公司趋势跟踪分析以及合作建议。
        </div>
      </v-alert>
      <v-alert
        v-else-if="formId === 'd4514d84-27ea-49e2-a4d7-2defc4deca96'"
        class="mt-6"
        border="top"
        colored-border
        type="info"
        elevation="2"
        color="#2196F3"
      >
        <div class="mt-2">
          <b>说明： </b>
        </div>
        <div class="mt-2">
          请各代表处、地区处、外资处、跨国公司研究中心、投资促进中心提供国际国内重要投资合作以及资本流动趋势情况。
        </div>
      </v-alert>
    </v-card>
    <!-- 外商推送 -->
    <pushtracks
      ref="pushtracks"
      :parent-id="projectId"
      :form-id="formId"
      @pushInitChild="pushInitChild"
    />
  </v-container>
</template>
<script>
  import _ from 'lodash'
  import FcEditor from '@form-create/component-wangeditor'
  import Vue from 'vue'
  import Upload from './component/Upload'
  import userPhoto from '../../assets/user_photo.jpg'
  import mapIcon from '../../assets/map_icon.png'
  export default {
    components: {
      // Upload: Upload,
      pushtracks: () => import('../report/component/Pushtracks'),
    },
    data: () => ({
      uploadList: [],
      mapIcon: mapIcon,
      userPhoto: userPhoto,
      formLoading: true, // 表单加载圈
      // 项目阶段选择下拉菜单数据源
      stages: [],
      formId: null,
      breadcrumbs: [ // 面包屑导航
        {
          text: '首页',
          disabled: false,
        },
        {
          text: '编辑',
          disabled: true,
        },
      ],
      formCreateRule: { // 表单创建规则
        // 实例对象
        fApi: {},
        // 表单生成规则
        rule: [],
        // 组件参数配置
        option: {
          submitBtn: false,
          form: {
            labelWidth: undefined,
            labelPosition: 'top',
          },
          row: {
            gutter: 30,
          },
        },
      },
      project: null,
      projectId: null,
      formAreaCode: '',
      T: null,
      map: null,
      lnglat: [],
      openlayer: null,
      graphicLayer: null,
      controls: null,
      vectors: null,
      featureArr: [],
      files: {},
      mapBox: false,
      formDatas: { formId: '1aade70e-95cd-45ca-b30b-c192a0111b78', page: 0, size: 999 },
      isAdmin: false,
      formDataBox: { articleId: '' },
      formDataBoxs: {},
      rumap: false,
      gdmap: false,
      markerBox: [],
      mapBoxs: false,
      // 会商审核
      ishsEdit: false,
      // 审核
      isEdit: false,
      // 外商
      wsFormId: 'bce0f632-42a8-4cf7-a30c-e5c6f00c6e62',
      // 推介
      tjFormId: 'defdcd74-fe79-4906-b3aa-dbdb0ce55afe',
      // 会商表（共用）
      searchParame: {
        formId: 'd3ced02a-aee1-48dc-9a54-93323876d62c',
        _from: 'public',
        page: 0,
      },
      isXs: false,
    }),
    watch: {
      // 监听路由是否变化，一旦变化重新设置表单id和拉取项目列表
      $route: {
        handler (to, from) {
          // this.createForm(this.formId, this.projectId)
          console.log('有变化')
        },
        deep: true,
        // 代表在wacth里声明了这个方法之后立即先去执行handler方法
        immediate: true,
      },
      '$vuetify.breakpoint.name': {
        handler (name) {
          if (name === 'xs') {
            this.isXs = true
          } else if (name === 'sm') {
            this.isXs = false
          } else {
            this.isXs = false
          }
        },
        deep: true,
        // 代表在wacth里声明了这个方法之后立即先去执行handler方法
        immediate: true,
      },
    },
    async created () {
      this.ishsEdit = localStorage.ishsEdit === 'true' || localStorage.ishsEdit === true;
      this.isEdit = localStorage.isEdit === 'true' || localStorage.isEdit === true;
      this.isAdmin = localStorage.isAdmin === 'true' || localStorage.isAdmin === true
      // 如果有表单ID，就获取赋值
      if (this.$route.query.formId) {
        this.formId = this.$route.query.formId
        if (JSON.parse(localStorage.newForms).length) {
          const formTypes = JSON.parse(localStorage.newForms)[0].children
          formTypes.map(val => {
            if (val.id === this.formId) {
              this.breadcrumbs[1].text = val.name + '编辑'
            }
          })
        }
      }
      // 如果有项目ID，就获取赋值
      if (this.$route.query.id) {
        this.projectId = this.$route.query.id
      }

      this.getFormsSelect()
    },
    mounted () {
      this.$nextTick(async () => {
        const isshow = await this.isMap()
        if (isshow) {
          await this.loadmap()
        }
        await this.createForm(this.formId, this.projectId)
      })
      Vue.component('uploadFiles', Upload)
      Vue.component('editor', FcEditor)
      Vue.component('Dividers', {
        props: ['value'],
        template: '<Divider plain>{{value}}</Divider>',
      })
      Vue.component('treeselect', {
        props: ['data', 'value'],
        watch: {
          value (val) {
            this.$emit('input', val)
          },
        },
        template: "<a-tree-select  v-model='value' :tree-check-strictly='true' :tree-data='data' multiple/>",
      });
      // 最多选两个
      Vue.component('treeselect2', {
        props: ['data', 'value', 'dictId', 'code'],
        data: () => ({
          datas: [],
          datas2: [],
        }),
        watch: {
          async value (val) {
            const list = []
            Object.keys(val).map(key => {
              list.push(val[key])
            })
						//注释限制选择两个
            // if (list.length >= 2) {
            //   this.datas2 = await this.getTreeDictData2(this.dictId, '0', this.code, 'more')
            //   this.$emit('input', val)
            // } else {
            //   this.$emit('input', val)
            // }
            this.$emit('input', val)
          },
        },
        async mounted () {
          this.datas = await this.getTreeDictData2(this.dictId, '0', this.code)
          this.datas2 = this.datas
        },
        methods: {
          async change (val) {
            const list = []
            Object.keys(val).map(key => {
              list.push(val[key])
            })
						//注释限制选择两个
            // if (list.length >= 2) {
            //   this.datas2 = await this.getTreeDictData2(this.dictId, '0', this.code, 'more')
            // } else {
            //   this.datas2 = this.datas
            // }
          },

          // 获取树形数据1
          async getTreeDictData2 (dictId, cityId, type, type2) {
            let tree = []
            if (dictId) {
              await this.$axios.get(`/dictionaries/${dictId}`)
                .then(res => {
                  const resData = res.data.data
                  resData.map(val => {
                    val.cid = val.id
                    // 级联菜单
                    val.value = val.code
                    val.label = val.name
                    // 树状菜单
                    val.title = val.name
                    val.id = val.code
                    if (type2) {
                      val.selectable = false
                    }
                  })
                  if (type === 'inferred' || type === 'pushCity') {
                    tree = this.treeData2(resData, cityId, 'inferred')
                    console.log('tree', tree);
                  } else {
                    tree = this.treeData2(resData, cityId)
                  }
                })
            }
            return tree
          },
          // 格式化用户组数据返回树形结构
          treeData2 (sourceData, id, type) {
            const cloneData = JSON.parse(JSON.stringify(sourceData))
            return cloneData.filter(father => {
              if (type) {
                const branchArr = cloneData.filter(child => father.cid === child.parentId)
                return father.parentId + '' === id + ''
              } else {
                const branchArr = cloneData.filter(child => father.cid === child.parentId)
                branchArr.length > 0 ? father.children = branchArr : father.children = []
                return father.parentId + '' === id + ''
              }
            })
          },
        },
        template: "<a-tree-select @change='change' v-model='value' :tree-check-strictly='true' :tree-data='datas2' multiple/>",
      });
    },
    methods: {
      mychange (val) {
        console.log(val)
        this.formDataBox.articleId = val
        this.formCreateRule.fApi.setValue('articleId', val)
      },
      clear () {
        if (this.marker) {
          this.marker.setMap(null)
          this.marker = null
          this.lnglat = ''
          this.formCreateRule.fApi.setValue('lnglat', this.lnglat)
        }
      },
      updateIcon () {
        this.marker.setIcon('//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png')
      },

      updateContent () {
        if (!this.marker) {
          return
        }

        // 自定义点标记内容
        var markerContent = document.createElement('div')

        // 点标记中的图标
        var markerImg = document.createElement('img')
        markerImg.className = 'markerlnglat'
        markerImg.src = '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png'
        markerContent.appendChild(markerImg)

        // 点标记中的文本
        var markerSpan = document.createElement('span')
        markerSpan.className = 'marker'
        markerSpan.innerHTML = 'Hi，我被更新啦！'
        markerContent.appendChild(markerSpan)

        this.marker.setContent(markerContent) // 更新点标记内容
        this.marker.setPosition([116.391467, 39.927761]) // 更新点标记位置
      },

      // 清除 marker
      clearMarker () {
        if (this.marker) {
          this.marker.setMap(null)
          this.marker = null
        }
      },
      async getFormsSelect () {
        const forms = await this.$axios.get('/forms')
        const formData = await this.$axios.get(`/forms/${this.formId}`)
        const formType = formData.data.data.categoryId
        this.stages = forms.data.data.filter(val => {
          return formType === val.categoryId
        })
        this.stages.sort((a, b) => {
          return a.weight - b.weight
        })
      },
      // 创建表单
      async createForm (formId, projectId) { // 赋值表单的创建规则
        this.formLoading = false // 隐藏加载图标
        const formRule = await this.formRule(formId) // 获取表单规则
        const ruleFormat = await this.ruleFormat(formRule) // 过滤表单规则
        this.formCreateRule.rule = ruleFormat // 将过滤后的规则赋值给表单组件
        const formData = await this.inputValue(projectId) // 获取项目数据
        if (formData) {
          this.formDataBox = formData
        }
        console.log('rule', this.formCreateRule.rule)

        await this.initFormData(formData, formRule, formId)
      },
      async isMap () {
        const formRule = await this.formRule(this.formId)
        for (var i = 0; i < formRule.length; i++) {
          if ((formRule[i].code === 'lnglat' || formRule[i].code === 'rangeDimension')) {
            // this.$refs.mapBox.$el.style.left = '0px'
            // this.$refs.mapBox.$el.style.position = 'relative'
            this.mapBox = true
            if (formRule[i].code === 'rangeDimension') {
              this.rumap = true
            }
            return true
          } else if ((formRule[i].code === 'lnglat')) {
            this.mapBoxs = true
          }
        }
      },
      // 获取创建表单的原始规则
      async formRule (formId) {
        if (formId) {
          const formData = await this.$axios.get('/forms/' + formId + '/fields')
          return formData.data.data
        }
      },
      // 创建表单规则，参数1：表单规则
      async ruleFormat (formRule) {
        const that = this
        // 规则存放数组
        const rule = []
        for (let i = 0; i < formRule.length; i++) {
          var obj = {}
          obj.title = { style: 'color:#0073AE', title: formRule[i].name }
          // 字段名称
          obj.field = formRule[i].code
          // 字段排序
          obj.weight = formRule[i].weight
          if (formRule[i].config) {
            obj.col = this.isXs ? { span: 24 } : formRule[i].config.col || { span: 24 }
            obj.props = formRule[i].config.props || {}
            if (formRule[i].config.title === 'null') {
              obj.title = ''
            }
          } else {
            obj.col = { span: 24 }
            obj.props = {}
          }
          // 验证规则
          obj.validate = []
          // 判断验证规则有哪些
          if (formRule[i].rules.includes('required')) {
            obj.validate.push({
              required: true,
              message: '此项必填!',
            })
          }
          if (formRule[i].rules.includes('numeric')) {
            obj.props.number = true
            obj.validate.push({
              type: 'number',
              message: '此项仅限数值!',
            })
          }
          // 表单默认值
          obj.props.placeholder = formRule[i].placeholder
          switch (formRule[i].type) { // 判断表单类型
            case 'rich-textarea':
              obj.type = 'editor'
              obj.props.init = (editor) => {
                editor.config.height = 500
                editor.config.uploadImgMaxLength = 100
                editor.config.uploadVideoMaxSize = 1 * 10 * 1024 * 1024 * 1024
                editor.config.uploadImgMaxSize = 1 * 100 * 1024 * 1024
                editor.config.customUploadImg = async (resultFiles, insertImgFn) => {
                  const formData = new window.FormData()
                  for (var i = 0; i < resultFiles.length; i++) {
                    formData.append('file', resultFiles[i])
                  }
                  const res = await that.$axios.post('/files', formData, {
                    headers: {
                      'Content-Type': 'multipart/form-data',
                    },
                  })
                  insertImgFn(res.data.data.url)
                }
                editor.config.customUploadVideo = async function (resultFiles, insertVideoFn) {
                  const formData = new window.FormData()
                  for (var i = 0; i < resultFiles.length; i++) {
                    formData.append('file', resultFiles[i])
                  }
                  const resp = await that.$axios.post('/files', formData, {
                    headers: {
                      'Content-Type': 'multipart/form-data',
                    },
                  })
                  insertVideoFn(resp.data.data.url)
                }
              }

              break
            // 多行文本类型
            case 'textarea':
              obj.type = 'input'
              // if (formRule[i].code === 'content') {
              //   obj.title = ''
              //   console.log('obj', obj);
              // }
              break
            case 'combobox':
              obj.type = 'autoComplete'
              obj.value = ''
              obj.props.data = await this.getAutoCompleteDictData(formRule[i].dictId)
              break
            case 'number':
              obj.type = 'InputNumber'
              obj.props.width = '100%'
              obj.props.min = 0
              obj.value = ''
              // 内资
              if (formRule[i].code === 'rmbMonth') {
                obj.on = {
                  'on-change': () => {
                    const rmbMonth = this.formCreateRule.fApi.getValue('rmbMonth')
                    if (rmbMonth && rmbMonth !== null && rmbMonth !== '') {
                      this.formCreateRule.fApi.setValue('rmbInPlace', Number(rmbMonth) + Number(this.rmbInPlace))
                    } else {
                      this.formCreateRule.fApi.setValue('rmbInPlace', '')
                    }
                  },
                }
              // 外资
              } else if (formRule[i].code === 'dollarMonth') {
                obj.on = {
                  'on-change': () => {
                    const dollarMonth = this.formCreateRule.fApi.getValue('dollarMonth')
                    // console.log(dollarMonth)
                    if (dollarMonth && dollarMonth !== null && dollarMonth !== '') {
                      this.formCreateRule.fApi.setValue('dollarInPlace', Number(dollarMonth) + Number(this.dollarInPlace))
                    } else {
                      this.formCreateRule.fApi.setValue('dollarInPlace', '')
                    }
                  },
                }
              } else if (formRule[i].code === 'ranking' && !this.projectId) {
                this.formCreateRule.fApi.hidden(true, 'ranking')
              }
              break
            case 'hidden':
              obj.type = 'hidden'
              if (obj.field.includes('addTitle')) {
                // eslint-disable-next-line no-undef
                obj = formCreate.maker.create('i-button').props({
                  type: 'error',
                  size: 'large',
                  class: 'add1active',
                }).native(false).col({
                  className: 'add1active',
                // eslint-disable-next-line no-undef
                }).children([formCreate.maker.create('span').domProps({
                  innerHTML: formRule[i].name,
                })])
              } else if (obj.field.includes('splitline')) {
              // obj.type = 'Dividers'
              // obj.props.value = obj.title
              // obj.title = ''
              }
              break
            case 'date':
              obj.type = 'DatePicker'
              if (formRule[i].code === 'onYear') {
                obj.props.type = 'year'
                if (!this.projectId) {
                  this.formCreateRule.fApi.hidden(true, 'onYear')
                }
              }
              break
            case 'select':
              obj.type = 'select'
              obj.props.clearable = true
              if (formRule[i].code === 'parentID') {
                obj.options = await this.getrouteType()
              } else {
                obj.options = await this.getSelectDictData(formRule[i].dictId)
                if (formRule[i].code === 'projectStatus') {
                  obj.on = {
                    'on-select': async (val) => {
                      if (val.value === '再推送') {
                        this.formCreateRule.fApi.hidden(false, 'pushCity')
                      } else {
                        this.formCreateRule.fApi.hidden(true, 'pushCity')
                      }
                    },
                  }
                }
              }
              break
            case 'selects':
              obj.type = 'select'
              obj.width = '100%'
              obj.props.multiple = true
              obj.options = await this.getSelectDictData(formRule[i].dictId)
              break
            case 'radiobox':
              obj.type = 'radio'
              obj.options = await this.getSelectDictData(formRule[i].dictId)
              console.log('formRule[i].code', formRule[i].code);
              if (formRule[i].code === 'isPush1') {
                this.formCreateRule.fApi.hidden(true, 'pushReason')
                obj.on = {
                  'on-change': (val) => {
                    console.log('val', val);
                    if (val === '是') {
                      this.formCreateRule.fApi.hidden(false, 'pushReason')
                    } else {
                      this.formCreateRule.fApi.hidden(true, 'pushReason')
                    }
                  },
                }
              }

              break
            case 'switch':
              obj.type = 'switch'
              obj.props = {
                trueValue: 'true',
                falseValue: 'false',
              }
              if (!this.isAdmin) {
                obj.type = 'hidden'
              }
              break
            case 'checkbox':
              obj.type = 'checkbox'
              obj.options = await this.getSelectDictData(formRule[i].dictId)
              if (formRule[i].code === 'companyCategory') {
                obj.on = {
                  'on-change': (val) => {
                    if (val && val.includes('世界500强')) {
                      this.formCreateRule.fApi.hidden(false, 'onYear')
                      this.formCreateRule.fApi.hidden(false, 'ranking')
                    } else {
                      this.formCreateRule.fApi.hidden(true, 'onYear')
                      this.formCreateRule.fApi.hidden(true, 'ranking')
                    }
                  },
                }
              }
              break
            case 'cascader':
              obj.type = 'cascader'
              obj.value = []
              if (obj.field === 'city') {
                obj.props.data = await this.getTreeDictData(formRule[i].dictId, '370000')
              } else {
                obj.props.data = await this.getTreeDictData(formRule[i].dictId, '0')
              }
              break
            case 'tree':
              if ((formRule[i].code === 'inferred' && this.formId === 'bce0f632-42a8-4cf7-a30c-e5c6f00c6e62') || (formRule[i].code === 'country' && this.formId === 'defdcd74-fe79-4906-b3aa-dbdb0ce55afe')) {
                obj.type = 'treeselect2'
                obj.props.code = formRule[i].code
                obj.props.dictId = formRule[i].dictId
              } else {
                obj.type = 'treeselect'
                obj.props.data = await this.getTreeDictData(formRule[i].dictId, '0', formRule[i].code)
              }
              // obj.props.multiple = true
              // obj.props.showCheckbox = true
              if (formRule[i].code === 'pushCity' && !this.projectId) {
                this.formCreateRule.fApi.hidden(true, 'pushCity')
              }
              break
            case 'file':
              obj.type = 'uploadFiles'
              break
            case 'files': {
              obj.type = 'uploadFiles'
              break
            }
            default:
              obj.type = 'input'
              if (obj.field === 'articleId') {
                obj.props.disabled = 'true'
              }
          }
          rule.push(obj)
        }
        // console.log(rule)
        return rule
      },

      progressBlem () {
        const item = {}
        item.categoryId = 'c8e3e20a-48d6-4868-8eea-e7bd3bfba490'
        if (this.formDataBox && this.formDataBox.articleId) {
          item.articleId = this.formDataBox.articleId
        }
        this.$refs.articledialog.open(item)
      },
      progressBlem2 () {
        const item = {}
        if (this.formDataBox && this.formDataBox.articleId) {
          item.articleId = this.formDataBox.articleId
        } else {
          return
        }
        console.log(item)
        this.$refs.detaildialog.open(item)
      },
      async getrouteType () {
        const resp = await this.$axios.get('/reports/query', {
          params: this.formDatas, // 搜索参数对象
        })
        const formData = []
        resp.data.data.content.forEach(val => {
          const list = {}
          list.label = val.name
          list.value = val.id
          formData.push(list)
        })
        return formData
      },
      // 获取自动补全数据
      async getAutoCompleteDictData (dictId) {
        const select = []
        if (dictId) {
          await this.$axios.get(`/dictionaries/${dictId}`)
            .then(res => {
              const resData = res.data.data
              resData.map(val => {
                select.push(val.code)
              })
            })
        }
        return select
      },
      // 获取选择列表数据
      async getSelectDictData (dictId) {
        const select = []
        await this.$axios.get(`/dictionaries/${dictId}`)
          .then(res => {
            const resData = res.data.data
            resData.map(val => {
              if (val.name === '吕涛书记重点调度项目') {
                select.push({
                  value: val.code,
                  label: val.name,
                })
              } else {
                select.push({
                  value: val.code,
                  label: val.name,
                })
              }
            })
          })
        return select
      },
      // 获取树形数据1
      async getTreeDictData (dictId, cityId, type) {
        let tree = []
        if (dictId) {
          await this.$axios.get(`/dictionaries/${dictId}`)
            .then(res => {
              const resData = res.data.data
              resData.map(val => {
                val.cid = val.id
                // 级联菜单
                val.value = val.code
                val.label = val.name
                // 树状菜单
                val.title = val.name
                val.id = val.code
              })
              if (type === 'inferred' || type === 'pushCity') {
                tree = this.treeData(resData, cityId, 'inferred')
                console.log('tree', tree);
              } else {
                tree = this.treeData(resData, cityId)
              }
            })
        }
        return tree
      },
      // 格式化用户组数据返回树形结构
      treeData (sourceData, id, type) {
        const cloneData = JSON.parse(JSON.stringify(sourceData))
        return cloneData.filter(father => {
          if (type) {
            // 园区市级不能选
            // if (father.parentId === 0) {
            //   father.disabled = true
            // }

            const branchArr = cloneData.filter(child => father.cid === child.parentId)
            // branchArr.length > 0 ? father.children = branchArr : father.children = []
            return father.parentId + '' === id + ''
          } else {
            const branchArr = cloneData.filter(child => father.cid === child.parentId)
            branchArr.length > 0 ? father.children = branchArr : father.children = []
            return father.parentId + '' === id + ''
          }
        })
      },
      // 初始化表单数据
      async initFormData (formData, formRule, formId) {
        const rule = await this.ruleFormat(formRule)
        if (formData) {
          console.log('formData', formData);
          if (formData.projectStatus === '再推送') {
            this.formCreateRule.fApi.hidden(false, 'pushCity')
          } else {
            this.formCreateRule.fApi.hidden(true, 'pushCity')
          }
          if (formData.companyCategory && formData.companyCategory.includes('世界500强')) {
            this.formCreateRule.fApi.hidden(false, 'onYear')
            this.formCreateRule.fApi.hidden(false, 'ranking')
          } else {
            this.formCreateRule.fApi.hidden(true, 'onYear')
            this.formCreateRule.fApi.hidden(true, 'ranking')
          }
          for (const key in formData) {
            rule.map(val => {
              if (val.field === key && (val.type === 'select' || val.type === 'selects' || val.type === 'checkbox' || val.type === 'cascader' || val.type === 'treeselect2' || val.type === 'treeselect' || val.type === 'tree' || val.type === 'file' || val.type === 'files' || val.type === 'uploadFiles' || (val.type === 'DatePicker' && val.props.type === 'daterange'))) {
                if (formData[key]) {
                  const formD = (Array.isArray(formData[key]) && formData[key]) ? formData[key] : formData[key].split(',')
                  if (key !== 'attachment') {
                    this.formCreateRule.fApi.setValue(key, formD)
                  } else {
                    this.formCreateRule.fApi.setValue(key, formD)
                  }
                }
              } else if (val.field === key && (val.type === 'InputNumber')) {
                if (!isNaN(formData[key])) {
                  this.formCreateRule.fApi.setValue(key, formData[key])
                }
              } else if (val.field === key && val.type === 'switch') {
                this.formCreateRule.fApi.setValue(key, formData[key])
              } else if (val.field === key) {
                if (val.field === 'lnglat') {
                  var lng
                  var lat
                  const OpenLayers = window.OpenLayers
                  if (this.formDataBox.lnglat) {
                    lng = JSON.parse(formData[key])[0].split(',')[0]
                    lat = JSON.parse(formData[key])[0].split(',')[1]
                    this.olmap.setCenter(new OpenLayers.LonLat(lng, lat), 18)
                    this.lnglat = JSON.parse(formData[key])
                    var style = {
                      externalGraphic: mapIcon,
                      graphicWidth: 50,
                      graphicHeight: 50,
                    }
                    JSON.parse(formData[key]).map(val => {
                      var pt = new OpenLayers.Geometry.Point(val.split(',')[0], val.split(',')[1])
                      var feature = new OpenLayers.Feature.Vector(pt, null, style)
                      this.graphicLayer.addFeatures([feature])
                    })
                  }
                  this.formCreateRule.fApi.setValue(key, formData[key])
                } else {
                  let data = formData[key]
                  for (let i = 0; i < val.validate.length; i++) {
                    if (val.validate[i].type && val.validate[i].type === 'number') {
                      data = parseInt(formData[key])
                      break
                    }
                  }
                  this.formCreateRule.fApi.setValue(key, data)
                }
              }
            })
          }
        }
      },
      // 获取项目信息
      async inputValue (projectId) {
        // 判断是否有项目ID，新增项目是没有项目ID的
        if (projectId) {
          const projectInfo = await this.$axios.get(`/reports/${projectId}/detail`)
          this.formDataBoxs = projectInfo.data.data
          console.log('this.formDataBoxs', this.formDataBoxs);

          return projectInfo.data.data.data
        } else {
          return null
        }
      },
      // 创建基础地图与图层
      loadmap () {
        const OpenLayers = window.OpenLayers
        const SDRasterLayer = window.SDRasterLayer
        const SDRSAnnoLayer = window.SDRSAnnoLayer
        this.olmap = new OpenLayers.Map('mapDiv', {
          allOverlays: true,
          numZoomLevels: 19,
          displayProjection: 'EPSG:4490',
        })
        // 影像图层初始化
        var sdimgLayer = new SDRasterLayer()
        // 注记图层初始化
        var sdannoLayer = new SDRSAnnoLayer()
        this.olmap.addLayer(sdimgLayer)
        this.olmap.addLayer(sdannoLayer)
        // this.setMarker(117.05910, 36.68027)
        this.olmap.setCenter(new OpenLayers.LonLat(117.05910, 36.68027), 18)
        // 创建矢量图层
        this.graphicLayer = new OpenLayers.Layer.Vector('graphicLayer', { style: OpenLayers.Util.extend({}, OpenLayers.Feature.Vector.style['default']) })
        this.olmap.addLayer(this.graphicLayer)

        // 设置矢量图层默认样式
        OpenLayers.Feature.Vector.style['default']['strokeWidth'] = '2'
        var renderer = OpenLayers.Util.getParameters(window.location.href).renderer
        renderer = (renderer) ? [renderer] : OpenLayers.Layer.Vector.prototype.renderers

        this.vectors = new OpenLayers.Layer.Vector('VectorLayer', {
          renderers: renderer,
        })
        this.olmap.addLayers([this.vectors])
        // 监听矢量图层编辑完成事件
        this.vectors.events.on({ sketchcomplete: this.rangeDimension })
        this.controls = {
          polygon: new OpenLayers.Control.DrawFeature(this.vectors, OpenLayers.Handler.Polygon),
          modify: new OpenLayers.Control.ModifyFeature(this.vectors),
        }
        this.addIcon()
        for (var key in this.controls) {
          this.olmap.addControl(this.controls[key])
        }
      },
      // 添加地图点击事件，并添加图标
      addIcon () {
        this.controls['polygon'].deactivate()
        this.controls['modify'].deactivate()
        this.olmap.events.register('click', null, this.MapClick)
      },
      // 添加坐标
      MapClick (e) {
        // const that = this
        const OpenLayers = window.OpenLayers
        var px = new OpenLayers.Pixel(e.clientX - this.$refs.mapDiv.getBoundingClientRect().left, e.clientY - this.$refs.mapDiv.getBoundingClientRect().top - 22)
        var lonlat = this.olmap.getLonLatFromPixel(px)
        var pt = new OpenLayers.Geometry.Point(lonlat.lon.toFixed(5), lonlat.lat.toFixed(5))
        var style = {
          externalGraphic: mapIcon,
          graphicWidth: 50,
          graphicHeight: 50,
        }
        var feature = new OpenLayers.Feature.Vector(pt, null, style)
        this.graphicLayer.removeAllFeatures()
        this.lnglat = []
        this.formCreateRule.fApi.setValue('lnglat', '')
        this.graphicLayer.addFeatures([feature])
        this.lnglat.push(lonlat.lon.toFixed(5) + ',' + lonlat.lat.toFixed(5))
        this.formCreateRule.fApi.setValue('lnglat', JSON.stringify(this.lnglat))
      },
      // 删除地图点击事件,接触图标绘制方法
      reIcon () {
        this.olmap.events.unregister('click', null, this.MapClick)
      },
      // 清除坐标并清空表单
      remove_icon () {
        this.graphicLayer.removeAllFeatures()
        this.lnglat = []
        this.formCreateRule.fApi.setValue('lnglat', '')
      //  this.olmap.events.unregister('click', null, this.MapClick)
      },
      // 绘制四至范围完成，并保存到表单
      rangeDimension (e) {
        const feature = []
        e.feature.geometry.components[0].components.map((val) => {
          feature.push([val.x, val.y])
        })
        this.featureArr.push(feature)
        this.formCreateRule.fApi.setValue('rangeDimension', JSON.stringify(this.featureArr))
      },
      // 绘制多边形
      toggleControl (element) {
        this.reIcon()
        const OpenLayers = window.OpenLayers
        for (const key in this.controls) {
          var control = this.controls[key]
          if (element === key) {
            // 开启绘制
            control.activate()
          } else {
            // 关闭绘制
            control.deactivate()
          }
          this.controls.modify.mode = OpenLayers.Control.ModifyFeature.RESHAPE
          this.controls.modify.mode |= OpenLayers.Control.ModifyFeature.ROTATE
          this.controls.modify.mode |= OpenLayers.Control.ModifyFeature.DRAG
          this.controls.modify.createVertices = true
        }
      },
      // 清除多边形并清空表单
      remove_vectors () {
        this.vectors.removeAllFeatures()
        this.rangeDimension = []
        this.formCreateRule.fApi.setValue('rangeDimension', '')
      },
      setMarker (lng, lat) {
        const OpenLayers = window.OpenLayers
        var pt = new OpenLayers.Geometry.Point(lng, lat)
        var style = {
          externalGraphic: mapIcon,
          graphicWidth: 50,
          graphicHeight: 50,
        }
        var feature = new OpenLayers.Feature.Vector(pt, null, style)
        this.graphicLayer.addFeatures([feature])
        this.olmap.setCenter(new OpenLayers.LonLat(lng, lat), 18)
      },
      // 保存按钮
      saveBtn:
        _.throttle(function () {
          this.formCreateRule.fApi.validate((valid) => {
            if (valid) {
              this.saveData(this.formId, this.projectId)
            } else {
              this.$swal({
                title: '表单验证未通过',
                text: '请查看红色表单项与提示',
                icon: 'warning',
                confirmButtonText: '知道了',
              })
            }
          })
        }, 1500, { trailing: false }),
      // 提交按钮
      submitBtn:
        _.throttle(function (type) {
          this.formCreateRule.fApi.validate((valid) => {
            if (valid) {
              this.submitData(this.formId, this.projectId, type)
            } else {
              this.$swal({
                title: '表单验证未通过',
                text: '请查看红色表单项与提示',
                icon: 'warning',
                confirmButtonText: '知道了',
              })
            }
          })
        }, 1500, { trailing: false }),
      // 格式化表单数据
			
      filterFormData () {
         return new Promise((resolve) => {
          this.formCreateRule.fApi.submit((formData) => {
            let ysFormData = formData;
						for (const data in ysFormData) {
							if (typeof ysFormData[data] === 'string') {
								if (ysFormData[data] === '') {
									ysFormData[data] = null
								} else {
									ysFormData[data] = ysFormData[data].trim()
								}
								console.log('data', data);
							} else if (ysFormData[data] instanceof Array) {
								console.log('data', data);
								if (ysFormData[data].length === 0) {
									ysFormData[data] = null
								} else if (ysFormData[data][0] === '') {
									ysFormData[data] = null
								} else {
									ysFormData[data] = ysFormData[data].toString()
								}
								// 对外推介区县分开
								if (this.formId === 'defdcd74-fe79-4906-b3aa-dbdb0ce55afe' && data === 'city' && ysFormData[data].length) {
									// const resp = await this.getSelectDictData('streetTree')
									// resp.map(val => {
									//   if (val.value === ysFormData[data].split(',')[0]) {
									//     ysFormData['city2'] = val.label
									//   } else if (val.value === ysFormData[data].split(',')[1]) {
									//     ysFormData['county2'] = val.label
									//   }
									// })
									ysFormData['city2'] = ysFormData[data].split(',')[0]
									ysFormData['county2'] = ysFormData[data].split(',')[1]
								}
							}
						}
            resolve(ysFormData); // 解析 Promise 并返回 ysFormData
          });
        });
      },
      // 保存表单数据
      async saveData (formId, id) {
        let requert = null
        const filterFormData = await this.filterFormData()
        if (formId === '9632d127-2e30-4945-aeb6-e7074b701c1a') {
          const res = await this.$axios.get('/reports/' + filterFormData.parentID + '/detail')
          console.log(res, filterFormData)
          const data = res.data.data.data
          filterFormData.parentName = data.name
          filterFormData.areaCode = data.areaCode
        }
        const weight = filterFormData.weight ? filterFormData.weight : 0
        if (!id) {
          if (localStorage.userName) {
            filterFormData.fillingUser = localStorage.userName
          }
          // 对外推介项目  默认显示
          if (formId === this.tjFormId) {
            filterFormData.isShow = '是'
            console.log('0000', filterFormData);
          }
          requert = this.$axios.post(`/forms/${formId}/reports3`, {
            data: filterFormData,
            status: 0,
            weight: weight,
          })
        } else {
          requert = this.$axios.put(`/reports/${id}/update3`, {
            data: filterFormData,
            weight: weight,
          })
        }
        requert.then((res) => {
          this.$swal({
            title: '保存成功!',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500,
          })
          this.$router.go(-1)
        })
          .catch((err) => {
            console.log(err)
            this.$swal({
              title: '保存失败!',
              icon: 'error',
            })
          })
      },

      async getOpinion (id) {
        const searchParame = this.searchParame
        searchParame.parentID = id
        const resp = await this.$axios.get('/reports/query', {
          params: searchParame,
        })
        const desserts = resp.data.data.content
        return desserts
      },
      // 提交表单数据  会商审核人员进行判断进入会商意见
      async submitData (formId, id, type) {
        const requert = null
        const filterFormData = await this.filterFormData()
        const weight = filterFormData.weight ? filterFormData.weight : 0
        // 会商审核人员
        if (type === 'first' && this.ishsEdit) {
          // 初审，终审第一次  审核+推送
          const resp = await this.getOpinion(id)
          // 终审意见默认不写会传 无
          if (resp.length === 1 && localStorage.roles.includes('ROLE_TZCJCFZRSHYJ') && !resp[0].tzcfzrOpinion) {
            this.$refs.pushtracks.open('first')
            this.$refs.pushtracks.openReport(formId, id, filterFormData, weight, requert)
          } else if (!resp.length) {
            this.$refs.pushtracks.open('first')
            this.$refs.pushtracks.openReport(formId, id, filterFormData, weight, requert)
          } else {
            // 会商意见终审已经填过，直接审核
            this.submitData2(formId, id, filterFormData, weight, requert)
          }
        } else {
          this.submitData2(formId, id, filterFormData, weight, requert)
        }
      },
      // 会商子组件提交信息
      async pushInitChild (item) {
        console.log('item', item);

        this.submitData2(item.formId, item.id, item.filterFormData, item.weight, item.requert)
      },

      async submitData2 (formId, id, filterFormData, weight, requert) {
        if (!id) {
          if (localStorage.userName) {
            filterFormData.fillingUser = localStorage.userName
          }
          // 对外推介项目  默认显示
          if (formId === this.tjFormId) {
            filterFormData.isShow = '是'
            console.log('0000', filterFormData);
          }
          requert = this.$axios.post(`/forms/${formId}/reports3`, {
            data: filterFormData,
            status: 0,
            weight: weight,
          })
        } else {
          requert = this.$axios.put(`/reports/${id}/update3`, {
            data: filterFormData,
            weight: weight,
          })
        }
        requert.then((res) => {
          // 第一次审核通过后不再走submit
          if (this.formDataBoxs.status === 0 || this.formDataBoxs.status === 10) {
            this.$swal({
              title: '提交成功!',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
            setTimeout(() => {
              this.$router.go(-1)
            }, 500);
          } else {
            let projectId = null
            if (id) {
              projectId = id
            } else {
              projectId = res.data.data.id
            }
            this.$axios.put(`/reports/${projectId}/submit`)
              .then(res => {
                this.$swal({
                  title: '提交成功!',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1500,
                })
                setTimeout(() => {
                  this.$router.go(-1)
                }, 500);
              })
              .catch((err) => {
                console.log(err)
                this.$swal({
                  title: '提交失败!',
                  icon: 'error',
                })
              })
          }
        })
          .catch((err) => {
            console.log(err)
            this.$swal({
              title: '提交失败!',
              icon: 'error',
            })
          })
      },
    },
  }
</script>
<style lang='scss' scoped>
.ivu-date-picker {
  display: block;
}
::v-deep .ivu-input-number {
  width: 100%;
}
.mapDiv {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 500px;
  touch-action: pan-y;
}
.openlayer {
  width: 100%;
  height: 300px;
}
.mapEditBox {
  text-align: center;
}
/* #regular-tables{
  position: relative;
}; */
/* #mapBox{
  position: absolute;
  left: -9999px;
} */
.amap-icon img,
.amap-marker-content img {
  width: 25px;
  height: 34px;
}

.marker {
  position: absolute;
  top: -20px;
  right: -118px;
  color: #fff;
  padding: 4px 10px;
  box-shadow: 1px 1px 1px rgba(10, 10, 10, 0.2);
  white-space: nowrap;
  font-size: 12px;
  font-family: '';
  background-color: #25a5f7;
  border-radius: 3px;
}

.input-card {
  width: 18rem;
  z-index: 170;
}

.input-card .btn {
  margin-right: 0.8rem;
}

.input-card .btn:last-child {
  margin-right: 0;
}
::v-deep .ivu-form {
  .ivu-row {
    .add1active {
      height: 60px;
      width: 100%;
      display: block;
      .ivu-form-item {
        z-index: 1;
        .ivu-form-item-content {
          .ivu-btn {
            background-color: #cd0019;
            span {
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}
.cardXs{
border:none
}
</style>
